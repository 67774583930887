<template>
  <div>
      <Category/>
  </div>
</template>

<script>
import Category from './components/Category'
export default {
  components: {
    Category,
  },
  data() {
    return {
    }
  },
}
</script>
